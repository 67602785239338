// Styles
import './Display_7.css';

// Icons
import MainLeftSectionIcon from './icons/garbage-bag.svg';
import Item_1_Icon from './icons/calendar.svg';
import Item_2_Icon from './icons/best-price.svg';
import Item_4_Icon from './icons/deadline.svg';
import Item_5_Icon from './icons/weighing-machine.svg';
import Item_6_Icon from './icons/hazardous-material.svg';

// Images
import MainRightSectionImage from './images/dumpster.jpg';

// Core
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Display_7 = () => {
    return (
        <div className='display-7-outer-container'>
            <div className='display-7-inner-container'>
                <div className='display-7-main-container'>
                    <div className='display-7-main-left-section'>
                        <img
                            className='display-7-main-left-section-icon'
                            src={MainLeftSectionIcon}
                            alt='Main Left Section Icon'
                        />
                        <p className='display-7-main-left-section-title'>Frequently Asked Questions</p>
                        <p className='display-7-main-left-section-statement'>
                            Here, you'll find answers to some of the most common questions our customers
                            have. If you don't find what you're looking for, please don't hesitate to <Link to="/contact" className='faq-contact-link'>contact us.</Link>
                        </p>
                    </div>
                    <div className='display-7-main-right-section'>
                        <img
                            className='display-7-main-right-section-image'
                            src={MainRightSectionImage}
                            alt='Main Right Section Image'
                        />
                    </div>
                </div>
                <div className='display-7-secondary-container'>
                    <div className='display-7-secondary-container-item'>
                        <img
                            className='display-7-item-icon'
                            src={Item_1_Icon}
                            alt='Item Icon'
                        />
                        <p className='display-7-item-title'>How long can I rent a dumpster?</p>
                        <p className='display-7-item-statement'>Our dumpsters are available for rent for up to 10 days. Additional days are charaged $10 per every additional day.</p>
                    </div>
                    <div className='display-7-secondary-container-item'>
                        <img
                            className='display-7-item-icon'
                            src={Item_2_Icon}
                            alt='Item Icon'
                        />
                        <p className='display-7-item-title'>What is the price for your dumpsters?</p>
                        <p className='display-7-item-statement'>Our 10 yard dumpsters can be rented for $275, our 20 yard dumpster can be rented for $325, and our 30 yard dumpsters can be rented for $425.</p>
                    </div>
                    <div className='display-7-secondary-container-item'>
                        <img
                            className='display-7-item-icon'
                            src={Item_4_Icon}
                            alt='Item Icon'
                        />
                        <p className='display-7-item-title'>What if I need a rental period extension?</p>
                        <p className='display-7-item-statement'>You may contact us directly and we can set up a price for your requirements.</p>
                    </div>
                    {/* <div className='display-7-secondary-container-item'>
                        <img
                            className='display-7-item-icon'
                            src={Item_5_Icon}
                            alt='Item Icon'
                        />
                        <p className='display-7-item-title'>Is there a weight limit?</p>
                        <p className='display-7-item-statement'>Yes, the weight limit for 20 yard dumpsters is 4,000 pounds and the weight limit for 30 yard dumpsters is 5,000 pounds.</p>
                    </div> */}
                    <div className='display-7-secondary-container-item'>
                        <img
                            className='display-7-item-icon'
                            src={Item_6_Icon}
                            alt='Item Icon'
                        />
                        <p className='display-7-item-title'>What can I not throw into the dumpster?</p>
                        <p className='display-7-item-statement'>Flammable materials, concrete, and anything else that can damage the dumpster in any way.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Display_7;
